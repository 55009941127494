// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../views/resources/fonts/NotoSans-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../views/resources/fonts/NotoSans-ExtraBold.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../views/resources/fonts/NotoSans-Light.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../views/resources/fonts/NotoSans-Medium.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../views/resources/fonts/NotoSans-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../../views/resources/fonts/NotoSans-SemiBold.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
exports.push([module.id, "@font-face{font-family:noto-sans-bold;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal}@font-face{font-family:noto-sans-extrabold;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");font-weight:400;font-style:normal}@font-face{font-family:noto-sans-light;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");font-weight:400;font-style:normal}@font-face{font-family:noto-sans-medium;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");font-weight:400;font-style:normal}@font-face{font-family:noto-sans-regular;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");font-weight:400;font-style:normal}@font-face{font-family:noto-sans-semibold;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ");font-weight:400;font-style:normal}.form-row[data-v-5fb2524d]{width:auto}.form-row .form-row-inner-container[data-v-5fb2524d]{-webkit-box-align:initial;-ms-flex-align:initial;align-items:normal}.form-row .form-row-inner-container.form-row-vertical .label-container .form-label-container[data-v-5fb2524d]{padding:.08rem 0}.form-row .form-row-inner-container.form-row-vertical .label-container .form-label-container .form-label[data-v-5fb2524d]{color:#ff0;padding-left:0!important}.form-row .form-row-inner-container.form-row-vertical .inputs-container .inputs-create-container[data-v-5fb2524d]{margin:0 .7rem;width:92%}.form-row .form-row-inner-container.form-row-vertical .inputs-container .inputs-create-container .select-style[data-v-5fb2524d]{padding-right:.08rem}.form-row .flex-container[data-v-5fb2524d]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:initial;-ms-flex-pack:initial;justify-content:normal;float:right}", ""]);
// Exports
module.exports = exports;
