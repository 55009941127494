





















import { Component, Vue, Prop } from 'vue-property-decorator';

// child components
import FormLabel from '@/components/FormLabel/FormLabel.vue';

@Component({ components: { FormLabel } })
export default class FormRow extends Vue {
    @Prop({ default: '' }) className: 'emphasis';
    @Prop({ default: false }) noLabel: boolean;
    @Prop({ default: false }) vertical: boolean;
}
